import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { CategoryType, Dictionary, StaticData, ViewValue } from 'src/app/shared/models/global';
import { MusicCategory } from '../../models/music.model';
import { ResidentPreferenceData, ResidentPreferenceDataRaw } from '@resparke/models';

export interface Resident {
  id: string;
  facilityId: string;
  preferenceId: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  roomNumber: string;
  program?: string[];
  family?: ResidentFamily[];
}

export interface ResidentFamily {
  id: string;
  firstName: string;
  lastName: string;
  confirmed?: boolean;
  relationship: string;
}


@Injectable({
  providedIn: 'root'
})
export class ResidentService {

  constructor(
    private apiService: ApiService,
    private appState: AppStateService,
  ) { }

  getCategories(params: {
    slug: string,
    type: CategoryType,
  }): Observable<ViewValue[]> {
    const data: Dictionary<any> = {
      type: params.type,
      slug: params.slug
    };

    const statement = `
      query getCategories($input: CategoryInput) {
        getCategories(input: $input) {
          category
          slug
          sort
        }
      }
    `;
    return this.apiService
      .graphql<MusicCategory[]>({ statement, variables: { input: data }, type: 'getCategories' })
      .pipe(
        map(result => result.map(val => ({ viewValue: val.category, value: val.slug })).sort((a, b) => a.viewValue < b.viewValue ? -1 : 1))
      )
  }

  getResident(input: { facilityId: string, residentId: string }): Observable<Resident> {
    const statement = `
      query getResident($input: GetResidentInput!) {
        getResident(input: $input) {
          id
          firstName
          lastName
          preferredName
          roomNumber
          preferenceId
          program
          family {
            id
            firstName
            lastName
            confirmed
          }
        }
      }
    `;
    return this.apiService
      .graphql<Resident>({ statement, variables: { input }, type: 'getResident' })
      .pipe(
        tap(resident => {
          this.appState.setState('currentResident', resident);
        })
      )
  }

  getStaticData(input: { items: string[]}): Observable<ViewValue[]> {
    const statement = `
      query getStaticData($input: StaticDataInput!) {
          getStaticData(input: $input) {
            name
            data
          }
        }
    `;
    return this.apiService
      .graphql<StaticData[]>({ statement, variables: { input }, type: 'getStaticData' } )
      .pipe(
        map<StaticData[], ViewValue[]>(res => {
          if (!res.length) {
            return [];
          } else if (res.length === 1) {
            return JSON.parse(res[0].data)
          }
        })
      )
  }

  getArtists(): Observable<ViewValue[]> {
    const statement = `
      query getArtists {
        getArtists {
          viewValue
          value
        }
      }
    `;
    return this.apiService
      .graphql<ViewValue[]>({ statement, variables: {}, type: 'getArtists' })
      .pipe(
        map(artists => artists.sort((a, b) => a.viewValue > b.viewValue ? 1 : -1))
      )

  }

  getResidentPreferenceData(preferenceId: string): Observable<boolean> {
    const input = {
      preferenceId,
    }
    const statement = `
      query getResidentPreferenceData($input: GetPreferencDataInput!) {
        getResidentPreferenceData(input: $input) {
          id
          profile {
            gender
            dob
          }
          music {
            genres
            favouriteArtists
            suggestedArtists
            memories
          }
          occupationsLife {
            industries
            suggestedOccupations
            memories
            achievements
          }
          languageCountry {
            primaryLanguage
            secondaryLanguage
            countryOfBirth
            countryGrewUp
            countryOtherPlaces
          }
          cultureValuesBelieves {
            religion
            suggestedReligion
            practiceReligion
            culturalBackground
            personalIdentity
            communityGroups
          }
          peoplePets {
            pets
            animals
            importantPeople {
              name
              relationship
            }
          }
          hobbiesInterestsSports {
            hobbiesInterests
            hobbiesMemories
            sports
            suggestedSports
            sportsMemories
          }

          conversationStarters {
            conversationStarters
          }
        }
      }
    `;
    return this.apiService
      .graphql<ResidentPreferenceDataRaw>({ statement, variables: { input }, type: 'getResidentPreferenceData' })
      .pipe(
        map(data => {
          const res: ResidentPreferenceData = {
            id: data.id,
            profile: data.profile.length ? data.profile[0]: undefined,
            music: data.music.length ? data.music[0]: undefined,
            cultureValuesBelieves: data.cultureValuesBelieves.length ? data.cultureValuesBelieves[0]: undefined,
            hobbiesInterestsSports: data.hobbiesInterestsSports.length ? data.hobbiesInterestsSports[0]: undefined,
            languageCountry: data.languageCountry.length ? data.languageCountry[0]: undefined,
            peoplePets: data.peoplePets.length ? data.peoplePets[0]: undefined,
            occupationsLife: data.occupationsLife.length ? data.occupationsLife[0]: undefined,
            conversationStarters: data.conversationStarters.length ? data.conversationStarters[0]: undefined,
          }
          console.log('res', res)
          this.appState.setState('currentResidentPreferenceData', res);
          return true;
        })
      )
  }
}
