import { AbstractControl } from '@angular/forms';

export function getErrorMessages(control: AbstractControl): string  {
// export const getErrorMessages = (key: string): string => {
  if (control.invalid) {
    // if we have more than one error on the field, remove required so we can show a more specific error message
    if (Object.keys(control.errors).length > 1) {
      delete control.errors.required;
    }
    const error = Object.keys(control.errors!)[0];
    switch (error) {
      case 'matDatepickerParse':
        return 'Please provide a correct date';
      case 'weakPassword':
        return 'This is a weak password';
      case 'passwordRuleFail':
        return 'Your password must be at least 8 characters and contain a mixture of capital letters and numbers';
      case 'required':
        return 'This field is required';
      case 'email':
        return 'Please enter a valid email address';
      case 'numbersAndLetters':
        return 'Please enter only numbers or letters';
      case 'minlength':
        return 'Please enter 4 numbers or letters';
      case 'pattern':
        return 'Please input a proper value'
      case 'requireMatch':
        return 'Select a value from the list'
      case 'min':
        return 'Please input a number greater than 0'
      default:
        return '';
    }
  } else {
    return '';
  }
}