import { v5 as uuidv5, v4 as uuidV4  } from 'uuid';

/**
 * Create a consistent guid based on a namespace and a value.
 * A namespace allows you to group UUIDS that are part of a same group and will result in the same UUID value when
 * you pass in the same namespace and value. A namespace should be something that is a generic group name, or sub group name.
 *
 * The value could be any value you want converted to a GUID.
 *`
 * Returns a 36 character GUID based on  SHA-1 algorithm
 *
 * Example usage: `createConsistentUUID('a name space value`, '423223442`)
 */
export const createConsistentUUID = (namespace: string, value: string) => {
  const _namespace = uuidv5(namespace, 'bb5d0ffa-9a4c-4d7c-8fc2-0a7d2220ba45');
  return uuidv5(value, _namespace);
}

export const createUUID = () => uuidV4();

export const truncateGuid = (guid: string) => {
  return guid.replace(/-/g, '');
}

export const untruncateGuid = (guid: string) => {
  return guid.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');
}