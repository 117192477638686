import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(dayOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);



export const getSydneyDate = (date: Date | number | string = new Date()) => {
  return dayjs(date).tz(TIMEZONES.SYDNEY);
}
export enum TIMEZONES {
  SYDNEY = 'Australia/Sydney'
}
