export const lookup = (obj: any, search: string, result: any = {}) => {
  if (!result[search]) {
    result = {
      [search]: [],
    };
  }
  Object.keys(obj).forEach(key => {
    if (key === search) {
      result[search].push(obj[key])
    } else if (obj[key]?.constructor === {}.constructor) {
      lookup(obj[key], search, result);

    } else if (obj[key]?.constructor === [].constructor) {
      for (const item of obj[key]) {
        lookup(item, search, result);
      }
    }
  });
  return result;
}

export const removeEmptyPropertyValues = (values: any) => {
  return Object.fromEntries(Object.entries(values).filter(([_, v]) => v != null));
}

export const replaceUndefinedForNull = (values: any) => {
  return Object.fromEntries(Object.entries(values).map(([_, v]) => v === undefined ? [_, null] : [_, v]));
}
